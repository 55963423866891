<template>
  <div @click="sortOpen = false">
    <HeaderBlock />
    <NotificationContinueActionBlock v-if="false" :authToken="authToken" />
    <section class="catalog-section page-section">
      <h1>{{ productCategory.name }}</h1>
      <div class="categories" id="categoriesList">
        <a
          class="link"
          :class="{ active: productCategory.id == null }"
          href="/catalog"
        >
          {{ locale.TEXTS.allText }} <span>{{ countAll }}</span>
        </a>
        <a
          v-for="item in categories"
          :key="item"
          class="link"
          :class="{ active: productCategory.id === item.id }"
          @click.stop="clickCategoryUrl(item.slug)"
        >
          {{ item.name }}<span>{{ item.count }}</span>
        </a>
        <div class="lot-links d-none">
          <span>{{ locale.TEXTS.lotText }}</span>
          <div></div>
        </div>
      </div>
      <div class="filters">
        <div
          class="sort"
          @click.stop="
            sortOpen === true ? (sortOpen = false) : (sortOpen = true)
          "
        >
          <span class="key">{{ locale.TEXTS.filterDisplay }}:</span>
          <span class="value" v-if="sortValue === 'popular'">
            {{ locale.TEXTS.sortPopular }}
          </span>
          <span class="value" v-else-if="sortValue === 'new'">
            {{ locale.TEXTS.sortNew }}
          </span>
          <span class="value" v-else-if="sortValue === 'price'">
            {{ locale.TEXTS.sortPrice }}
          </span>
          <span class="value" v-else-if="sortValue === '-price'">
            {{ locale.TEXTS.sortMinusPrice }}
          </span>
          <div v-if="sortOpen" class="block">
            <span
              :class="{ active: sortValue === 'popular' }"
              @click.stop="(sortValue = 'popular'), (sortOpen = false)"
            >
              {{ locale.TEXTS.sortPopular }}
            </span>
            <span
              :class="{ active: sortValue === 'price' }"
              @click.stop="(sortValue = 'price'), (sortOpen = false)"
            >
              {{ locale.TEXTS.sortPrice }}
            </span>
            <span
              :class="{ active: sortValue === '-price' }"
              @click.stop="(sortValue = '-price'), (sortOpen = false)"
            >
              {{ locale.TEXTS.sortMinusPrice }}
            </span>
            <span
              :class="{ active: sortValue === 'new' }"
              @click.stop="(sortValue = 'new'), (sortOpen = false)"
            >
              {{ locale.TEXTS.sortNew }}
            </span>
          </div>
        </div>
        <span
          class="button-filter"
          v-if="filters.length > 0"
          @click="openFilters"
        >
          {{ locale.TEXTS.filterText }} ({{ filters.length }})
        </span>
        <span v-else class="button-filter" @click="openFilters">
          {{ locale.TEXTS.filterText }}
        </span>
        <span class="clear-filter" v-if="filters.length > 0">
          {{ locale.TEXTS.filterClear }}
        </span>
        <div class="items">
          <span v-for="item in filters" :key="item">
            {{ item.name }}
            <span @click="deleteFilters(item.type, item.id)"></span>
          </span>
        </div>
      </div>
      <div v-if="products.length > 0" class="products-items">
        <ProductItemBlock
          v-for="(item, index) in products"
          :product="item"
          :key="item"
          :index="index"
          :isFavorite="true"
          :isBasket="true"
          :type="'catalog'"
          :sourcePage="6"
        />
      </div>
      <div
        v-if="isDisplayLot"
        @click="displayProducts(pageNumber + 1)"
        class="lot-loading-products"
      >
        <span>{{ locale.TEXTS.lotDisplay }}</span>
      </div>
    </section>
  </div>
  <div v-if="isOpenFilters" class="catalog-filters-popup shop-popup">
    <div class="content">
      <img
        @click="isOpenFilters = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <span class="close-button" @click="isOpenFilters = false">
        {{ locale.TEXTS.close }}
      </span>
      <h5>{{ locale.TEXTS.filterText }}</h5>
      <perfect-scrollbar>
        <div class="items">
          <div class="now-filters">
            <span v-for="item in filters" :key="item">
              {{ item.name }}
              <span @click="deleteFilters(item.type, item.id)"></span>
            </span>
          </div>
          <div>
            <h6 class="actions">{{ locale.TEXTS.filterAction }}</h6>
            <div class="actions">
              <label v-for="item in actions" :key="item">
                <input
                  type="checkbox"
                  v-model="item.is_checked"
                  data-type="actions"
                  v-bind:data-id="item.id"
                />
                <div class="text">{{ item.name }}</div>
              </label>
            </div>
            <h6 class="for-whom">{{ locale.TEXTS.filterForWhom }}</h6>
            <div class="for-whom">
              <label v-for="item in forWhom" :key="item">
                <input
                  type="checkbox"
                  v-model="item.is_checked"
                  data-type="forWhom"
                  v-bind:data-id="item.id"
                />
                <div class="text">{{ item.name }}</div>
              </label>
            </div>
          </div>
          <div>
            <h6 class="releases-form">{{ locale.TEXTS.filterReleaseForm }}</h6>
            <div class="releases-form">
              <label v-for="item in releasesForm" :key="item">
                <input
                  type="checkbox"
                  v-model="item.is_checked"
                  data-type="releasesForm"
                  v-bind:data-id="item.id"
                />
                <div class="text">{{ item.name }}</div>
              </label>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
      <div class="buttons buttons-save">
        <button v-if="countChecked > 0" @click="setFilters">
          {{ locale.TEXTS.filterSubmit }} ({{ countChecked }})
        </button>
        <button v-else @click="setFilters">
          {{ locale.TEXTS.filterSubmit }}
        </button>
        <a @click="clearFilters(true)">{{ locale.TEXTS.filterClear }}</a>
      </div>
      <div class="buttons buttons-back d-none">
        <a>{{ locale.TEXTS.returnBack }}</a>
        <button>{{ locale.TEXTS.filterSubmit }}</button>
      </div>
    </div>
  </div>
  <AuthRegBlock
    v-if="isOpenAuth && locale.LOCALE === 'ru'"
    :authText="authText"
  />
  <PreviewProducts
    v-if="productPreview != null"
    :products="products"
    :initialSlide="productPreview"
    :type="'catalog'"
    :sourcePage="6"
  />
  <FooterBlock />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import productMixin from "@/mixins/productMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import NotificationContinueActionBlock from "@/components/NotificationContinueActionBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import AuthRegBlock from "@/components/AuthRegBlock.vue";
import ProductItemBlock from "@/components/ProductItemBlock.vue";
import PreviewProducts from "@/components/PreviewProducts.vue";
import api from "@/api";
import Locale from "@/api/locale";

export default {
  mixins: [mainMixin, productMixin],
  components: {
    HeaderBlock,
    NotificationContinueActionBlock,
    FooterBlock,
    AuthRegBlock,
    ProductItemBlock,
    PreviewProducts,
  },
  props: {
    productCategory: Object,
    slug: String,
    profile: Object,
  },
  data() {
    return {
      isOpenFilters: false,
      sortOpen: false,
      sortValue: "popular",
      pageNumber: 1,
      products: [],
      categories: {},
      components: {},
      actions: {},
      forWhom: {},
      releasesForm: {},
      countAll: 0,
      filters: [],
      filtersGet: {},
      countChecked: 0,
      isOpenAuth: null,
      isDisplayLot: false,
      locale: Locale,
      productPreview: null,
      lastCatalogUrl: null,
    };
  },

  async mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("is-auth")) {
      history.pushState(
        null,
        null,
        location.protocol + "//" + location.host + location.pathname
      );
    }
    try {
      let result = await api.shop.getParamsCatalog();
      result = result["data"];
      for (let item in result["categories"]) {
        item = result["categories"][item];
        let slug = "";
        if (item["shop_page"].length > 0) {
          slug = item["shop_page"][0]["url"];
        }
        this.categories[item["id"]] = {
          id: item["id"],
          name: item["name"],
          count: item["count"],
          slug: slug,
        };
      }
      for (let item in result["components"]) {
        item = result["components"][item];
        this.components[item["id"]] = {
          id: item["id"],
          name: item["name"],
          is_checked: false,
        };
      }
      for (let item in result["actions"]) {
        item = result["actions"][item];
        this.actions[item["id"]] = {
          id: item["id"],
          name: item["name"],
          is_checked: false,
        };
      }
      for (let item in result["for_whom"]) {
        item = result["for_whom"][item];
        this.forWhom[item["id"]] = {
          id: item["id"],
          name: item["name"],
          is_checked: false,
        };
      }
      for (let item in result["releases_form"]) {
        item = result["releases_form"][item];
        this.releasesForm[item["id"]] = {
          id: item["id"],
          name: item["name"],
          is_checked: false,
        };
      }
      this.getUrlParams(urlParams);
      this.displayProducts(1);
      let that = this;
      setTimeout(function () {
        that.loadCategories();
      }, 100);
    } catch (error) {
      console.log(error);
    }

    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        that.isOpenFilters = false;
        that.isOpenAuth = false;
        that.isOpenPopup(false);
      }
    });
  },
  watch: {
    sortValue() {
      this.setUrlParams();
      this.displayProducts(1);
    },
    components: {
      handler: function () {
        this.setCountChecked();
      },
      deep: true,
    },
    actions: {
      handler: function () {
        this.setCountChecked();
      },
      deep: true,
    },
    forWhom: {
      handler: function () {
        this.setCountChecked();
      },
      deep: true,
    },
    releasesForm: {
      handler: function () {
        this.setCountChecked();
      },
      deep: true,
    },
    isOpenFilters(isOpen) {
      this.isOpenPopup(isOpen);
    },
    isOpenAuth(isOpen) {
      this.isOpenPopup(isOpen);
    },
  },
  methods: {
    clickCategoryUrl(value) {
      value = this.getValueUrlParams(value);
      location.href = value;
      return false;
    },
    setCountChecked() {
      let popup = document.querySelector(".catalog-filters-popup");
      if (!popup) return false;
      let count = popup.querySelectorAll("label input:checked").length;
      this.countChecked = count;
      if (window.innerWidth < 1200) {
        this.countChecked = popup.querySelectorAll(
          "label input:checked"
        ).length;
        this.rejectCountChecked();
      }
    },
    rejectCountChecked() {
      let popup = document.querySelector(".catalog-filters-popup");
      let button_block = popup.querySelector(".buttons-back button");
      let button_a = popup.querySelector(".buttons-back a");
      popup = popup.querySelector(".items > div > div:not(.d-none)");
      let count = popup.querySelectorAll("label input:checked").length;
      this.countChecked = count;
      if (count === 0) {
        button_block.classList.add("d-none");
        button_a.innerHTML = Locale.TEXTS.returnBack;
      } else {
        if (button_block.classList.contains("d-none")) {
          button_block.classList.remove("d-none");
        }
        button_block.innerHTML = Locale.TEXTS.filterSubmit + " (" + count + ")";
        button_a.innerHTML = Locale.TEXTS.filterClearAll;
      }

      let h6_components = document.querySelector("h6.components");
      let h6_actions = document.querySelector("h6.actions");
      let h6_for_whom = document.querySelector("h6.for-whom");
      let h6_releases_form = document.querySelector("h6.releases-form");
      let count_components = document.querySelectorAll(
        "div.components label input:checked"
      ).length;
      let count_actions = document.querySelectorAll(
        "div.actions label input:checked"
      ).length;
      let count_for_whom = document.querySelectorAll(
        "div.for-whom label input:checked"
      ).length;
      let count_releases_form = document.querySelectorAll(
        "div.releases-form label input:checked"
      ).length;

      if (count_components > 0) {
        h6_components.innerHTML =
          Locale.TEXTS.filterComponent + " (" + count_components + ")";
      } else h6_components.innerHTML = Locale.TEXTS.filterComponent;
      if (count_actions > 0) {
        h6_actions.innerHTML =
          Locale.TEXTS.filterAction + " (" + count_actions + ")";
      } else h6_actions.innerHTML = Locale.TEXTS.filterAction;
      if (count_for_whom > 0) {
        h6_for_whom.innerHTML =
          Locale.TEXTS.filterForWhom + " (" + count_for_whom + ")";
      } else h6_for_whom.innerHTML = Locale.TEXTS.filterForWhom;
      if (count_releases_form > 0) {
        h6_releases_form.innerHTML =
          Locale.TEXTS.filterReleaseForm + " (" + count_releases_form + ")";
      } else h6_releases_form.innerHTML = Locale.TEXTS.filterReleaseForm;
    },
    clearFilters(isClear) {
      this.isOpenFilters = false;
      this.filters = [];
      for (let item in this.components) {
        this.components[item].is_checked = false;
      }
      for (let item in this.actions) {
        this.actions[item].is_checked = false;
      }
      for (let item in this.forWhom) {
        this.forWhom[item].is_checked = false;
      }
      for (let item in this.releasesForm) {
        this.releasesForm[item].is_checked = false;
      }
      this.countChecked = 0;
      if (isClear) {
        this.setUrlParams();
        this.displayProducts(1);
      }
    },
    setFilters() {
      this.isOpenFilters = false;
      this.filters = [];
      let elements = document.querySelectorAll(
        ".catalog-filters-popup label input:checked"
      );
      for (let i = 0; i < elements.length; i++) {
        this.filters.push({
          name: elements[i].parentNode.querySelector("div").innerText,
          type: elements[i].getAttribute("data-type"),
          id: elements[i].getAttribute("data-id"),
        });
      }
      this.setUrlParams();
      this.displayProducts(1);
    },
    deleteFilters(type, id) {
      let filters = [];
      for (let item in this.filters) {
        if (this.filters[item].id !== id || type !== this.filters[item].type) {
          filters.push(this.filters[item]);
        }
      }
      if (type === "components") this.components[id].is_checked = false;
      if (type === "actions") this.actions[id].is_checked = false;
      if (type === "forWhom") this.forWhom[id].is_checked = false;
      if (type === "releasesForm") this.releasesForm[id].is_checked = false;
      this.filters = filters;
      this.setUrlParams();
      this.displayProducts(1);
    },
    openFilters() {
      let that = this;
      let filters = this.filters;
      this.clearFilters(false);
      for (let item in filters) {
        item = filters[item];
        if (item.type === "components") {
          this.components[item.id].is_checked = true;
        }
        if (item.type === "actions") this.actions[item.id].is_checked = true;
        if (item.type === "forWhom") this.forWhom[item.id].is_checked = true;
        if (item.type === "releasesForm") {
          this.releasesForm[item.id].is_checked = true;
        }
        this.filters.push(item);
      }
      let countChecked = this.filters.length;
      this.countChecked = countChecked;
      this.isOpenFilters = true;
      if (window.innerWidth < 1200) {
        setTimeout(function () {
          let button_a = document.querySelector(
            ".catalog-filters-popup .buttons-save a"
          );
          let button_block = document.querySelector(
            ".catalog-filters-popup .buttons-save button"
          );
          if (countChecked === 0) {
            button_a.innerHTML = Locale.TEXTS.closeFilter;
            button_block.classList.add("d-none");
          } else {
            button_a.innerHTML = Locale.TEXTS.filterClearAll;
            button_block.innerHTML = Locale.TEXTS.displayProducts;
          }

          let buttons_save = document.querySelector(
            ".catalog-filters-popup .buttons-save"
          );
          let buttons_back = document.querySelector(
            ".catalog-filters-popup .buttons-back"
          );
          let buttons_back_a = document.querySelector(
            ".catalog-filters-popup .buttons-back a"
          );
          let buttons_back_button = document.querySelector(
            ".catalog-filters-popup .buttons-back button"
          );
          let h6_components = document.querySelector("h6.components");
          let h6_actions = document.querySelector("h6.actions");
          let h6_for_whom = document.querySelector("h6.for-whom");
          let h6_releases_form = document.querySelector("h6.releases-form");
          let block_components = document.querySelector("div.components");
          let block_actions = document.querySelector("div.actions");
          let block_for_whom = document.querySelector("div.for-whom");
          let block_releases_form = document.querySelector("div.releases-form");
          let block_now_filters = document.querySelector(".now-filters");
          block_components.classList.add("d-none");
          block_actions.classList.add("d-none");
          block_for_whom.classList.add("d-none");
          block_releases_form.classList.add("d-none");

          h6_components.onclick = function () {
            h6_components.classList.add("d-none");
            h6_actions.classList.add("d-none");
            h6_for_whom.classList.add("d-none");
            h6_releases_form.classList.add("d-none");
            h6_releases_form.classList.add("d-none");
            buttons_save.classList.add("d-none");
            buttons_back.classList.remove("d-none");
            block_components.classList.remove("d-none");
            if (block_now_filters) {
              block_now_filters.classList.add("d-none");
            }
            that.rejectCountChecked();
          };

          h6_actions.onclick = function () {
            h6_components.classList.add("d-none");
            h6_actions.classList.add("d-none");
            h6_for_whom.classList.add("d-none");
            h6_releases_form.classList.add("d-none");
            h6_releases_form.classList.add("d-none");
            buttons_save.classList.add("d-none");
            buttons_back.classList.remove("d-none");
            block_actions.classList.remove("d-none");
            if (block_now_filters) {
              block_now_filters.classList.add("d-none");
            }
            that.rejectCountChecked();
          };

          h6_for_whom.onclick = function () {
            h6_components.classList.add("d-none");
            h6_actions.classList.add("d-none");
            h6_for_whom.classList.add("d-none");
            h6_releases_form.classList.add("d-none");
            buttons_save.classList.add("d-none");
            buttons_back.classList.remove("d-none");
            block_for_whom.classList.remove("d-none");
            if (block_now_filters) {
              block_now_filters.classList.add("d-none");
            }
            that.rejectCountChecked();
          };

          h6_releases_form.onclick = function () {
            h6_components.classList.add("d-none");
            h6_actions.classList.add("d-none");
            h6_for_whom.classList.add("d-none");
            h6_releases_form.classList.add("d-none");
            buttons_save.classList.add("d-none");
            buttons_back.classList.remove("d-none");
            block_releases_form.classList.remove("d-none");
            if (block_now_filters) {
              block_now_filters.classList.add("d-none");
            }
            that.rejectCountChecked();
          };

          function reset_buttons_back(isNext = false) {
            h6_components.classList.remove("d-none");
            h6_actions.classList.remove("d-none");
            h6_for_whom.classList.remove("d-none");
            h6_releases_form.classList.remove("d-none");
            block_components.classList.add("d-none");
            block_actions.classList.add("d-none");
            block_for_whom.classList.add("d-none");
            block_releases_form.classList.add("d-none");

            buttons_save.classList.remove("d-none");
            buttons_back.classList.add("d-none");
            if (isNext) {
              let count = document.querySelectorAll(
                ".catalog-filters-popup label input:checked"
              ).length;
              button_block = document.querySelector(
                ".catalog-filters-popup .buttons-save button"
              );
              if (count === 0) {
                button_a.innerHTML = Locale.TEXTS.closeFilter;
                button_block.classList.add("d-none");
              } else {
                button_a.innerHTML = Locale.TEXTS.filterClearAll;
                button_block.innerHTML = Locale.TEXTS.displayProducts;
              }
            }
          }

          function clear_input_checked(block) {
            let elements = block.querySelectorAll("input:checked");
            for (let i = 0; i < elements.length; i++) {
              elements[i].checked = false;
            }
          }

          buttons_back_a.onclick = function () {
            if (!block_components.classList.contains("d-none")) {
              clear_input_checked(block_components);
            }
            if (!block_actions.classList.contains("d-none")) {
              clear_input_checked(block_actions);
            }
            if (!block_for_whom.classList.contains("d-none")) {
              clear_input_checked(block_for_whom);
            }
            if (!block_releases_form.classList.contains("d-none")) {
              clear_input_checked(block_releases_form);
            }
            reset_buttons_back();
          };

          buttons_back_button.onclick = function () {
            reset_buttons_back(true);
          };
        }, 5);
      }
    },
    async displayProducts(page) {
      if (page === 1) this.products = [];
      try {
        this.pageNumber = page;
        let payload = this.filtersGet;
        payload.page_size = page;
        payload.count = 9;
        payload.sortValue = this.sortValue;
        if (this.productCategory.id) {
          payload.category_id = this.productCategory.id;
        }
        const result = await api.shop.getProductsCatalog(payload);
        for (let item in result["data"]["results"]) {
          this.products.push(result["data"]["results"][item]);
        }
        if (result["data"]["count"] > this.products.length) {
          this.isDisplayLot = true;
        } else this.isDisplayLot = false;
        let that = this;
        setTimeout(function () {
          that.updateNav();
        }, 100);
      } catch (error) {
        console.log(error);
      }
    },
    loadCategories() {
      let block = document.getElementById("categoriesList");
      if (block.getBoundingClientRect().height > 50) {
        let lot = block.querySelector(".lot-links");
        lot.classList.remove("d-none");
        lot = lot.querySelector("div");
        let links = block.querySelectorAll(".link");
        let link = links[links.length - 1];
        let newLink = link;
        newLink.classList.remove("link");
        link.remove();
        lot.append(newLink);
        this.loadCategories();
      }
    },
    getValueUrlParams(value = null) {
      this.filtersGet = {};
      let baseUrl = window.location.protocol + "//";
      baseUrl += window.location.host;
      if (value) baseUrl += "/catalog/" + value;
      else baseUrl += window.location.pathname;
      baseUrl += "?sort=" + this.sortValue;
      let components = "";
      for (let item in this.components) {
        item = this.components[item];
        if (item.is_checked && components === "") components = item["id"];
        else if (item.is_checked) components += "," + item["id"];
      }
      if (components !== "") {
        baseUrl += "&components=" + components;
        this.filtersGet["components"] = components;
      }
      let actions = "";
      for (let item in this.actions) {
        item = this.actions[item];
        if (item.is_checked && actions === "") actions = item["id"];
        else if (item.is_checked) actions += "," + item["id"];
      }
      if (actions !== "") {
        baseUrl += "&actions=" + actions;
        this.filtersGet["actions"] = actions;
      }
      let forWhom = "";
      for (let item in this.forWhom) {
        item = this.forWhom[item];
        if (item.is_checked && forWhom === "") forWhom = item["id"];
        else if (item.is_checked) forWhom += "," + item["id"];
      }
      if (forWhom !== "") {
        baseUrl += "&forWhom=" + forWhom;
        this.filtersGet["forWhom"] = forWhom;
      }
      let releasesForm = "";
      for (let item in this.releasesForm) {
        item = this.releasesForm[item];
        if (item.is_checked && releasesForm === "") releasesForm = item["id"];
        else if (item.is_checked) releasesForm += "," + item["id"];
      }
      if (releasesForm !== "") {
        baseUrl += "&releasesForm=" + releasesForm;
        this.filtersGet["releasesForm"] = releasesForm;
      }
      return baseUrl;
    },
    setUrlParams(value = null) {
      let baseUrl = this.getValueUrlParams(value);
      if (value) window.location.href = baseUrl;
      else history.pushState(null, null, baseUrl);
      this.getCountProductsCatalog();
    },
    getUrlParams(value) {
      this.filtersGet = {};
      let components = value.get("components");
      if (components) {
        this.filtersGet["components"] = components;
        components = components.split(",");
        for (let item in components) {
          item = Number(components[item]);
          this.filters.push({
            name: this.components[item].name,
            type: "components",
            id: this.components[item].id,
          });
        }
      }
      let actions = value.get("actions");
      if (actions) {
        this.filtersGet["actions"] = actions;
        actions = actions.split(",");
        for (let item in actions) {
          item = Number(actions[item]);
          this.filters.push({
            name: this.actions[item].name,
            type: "actions",
            id: this.actions[item].id,
          });
        }
      }
      let forWhom = value.get("forWhom");
      if (forWhom) {
        this.filtersGet["forWhom"] = forWhom;
        forWhom = forWhom.split(",");
        for (let item in forWhom) {
          item = Number(forWhom[item]);
          this.filters.push({
            name: this.forWhom[item].name,
            type: "forWhom",
            id: this.forWhom[item].id,
          });
        }
      }
      let releasesForm = value.get("releasesForm");
      if (releasesForm) {
        this.filtersGet["releasesForm"] = releasesForm;
        releasesForm = releasesForm.split(",");
        for (let item in releasesForm) {
          item = Number(releasesForm[item]);
          this.filters.push({
            name: this.releasesForm[item].name,
            type: "releasesForm",
            id: this.releasesForm[item].id,
          });
        }
      }
      let sort = value.get("sort");
      if (sort) this.sortValue = sort;
      this.openFilters();
      this.isOpenFilters = false;
      this.getCountProductsCatalog();
    },
    async getCountProductsCatalog() {
      try {
        let result = await api.shop.getCountProductsCatalog(this.filtersGet);
        for (let item in result["data"]) {
          try {
            this.categories[item].count = result["data"][item];
          } catch {
            this.countAll = result["data"][item];
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
