<template>
  <div class="product-header">
    <HeaderBlock />
  </div>
  <section class="product-section page-section">
    <a class="page-back" :href="pageBack">
      <span>{{ locale.TEXTS.backCatalog }}</span>
    </a>
    <span
      v-if="locale.LOCALE === 'ru'"
      class="product-favorite"
      @click.stop="clickFavoriteProduct(product)"
      :class="
        'favorite-page-' +
        product.id +
        ' favorite-' +
        product.id +
        ' ' +
        product.isFavorite
      "
    ></span>
    <span class="product-shared" @click="clickShare"></span>
    <div class="product-preview">
      <div class="product-images unselectable">
        <swiper
          class="swiper"
          :modules="modules"
          :slides-per-view="1"
          :loop="true"
          :pagination="{ type: 'bullets' }"
          :navigation="product.images.length > 1 ? true : false"
          :allow-touch-move="valueAllowTouchMove"
          :style="{
            '--swiper-navigation-color': '#000',
            '--swiper-navigation-size': '32px',
          }"
        >
          <swiper-slide v-for="(item, index) in product.images" :key="item">
            <span
              class="image-item"
              :style="{ background: item.image.background_color }"
              @click.stop="(event) => clickOpenPopupGallery(event, index)"
            >
              <img :src="item.image.image_object_big" />
            </span>
          </swiper-slide>
        </swiper>
        <span v-if="productPercent" class="discount-block">
          - {{ productPercent }}%
        </span>
      </div>
      <div class="product-cart">
        <div v-if="countFeedback > 0" class="product-rating">
          <a :href="'/catalog/' + slug + '/feedback'">
            {{ countFeedback }} {{ textFeedback }}
          </a>
          <span class="stars" v-html="starsFeedback"></span>
        </div>
        <h1>
          <span
            v-if="giftCardTypeSelected === 5"
            @click.stop="selectGiftCardType(0)"
          >
            Пластиковая
          </span>
          <span
            v-if="giftCardTypeSelected === 6"
            @click.stop="selectGiftCardType(0)"
          >
            Цифровая
          </span>
          {{ product.headline_h1 }}
          <span
            v-if="giftCardNominalSelected"
            @click.stop="selectGiftCardNominal(null)"
          >
            на {{ giftCardNominalSelected.headline_preview }} рублей
          </span>
        </h1>
        <perfect-scrollbar
          :options="optionsPS"
          :watch-options="false"
          class="product-combined-ps"
        >
          <div class="product-combined-items" v-if="combinedGoods.length > 1">
            <a
              v-for="item in combinedGoods"
              :key="item"
              :href="'/catalog/' + item.shop_page[0].url"
              :class="{ active: product.id === item.id }"
            >
              <img v-if="item.photo" :src="item.photo.image_object_small" />
              <img v-else :src="item.images[0].image.image_object_small" />
              <span class="discount" v-if="item.discount">
                {{ item.discount }}
              </span>
              <span class="text">{{ item.text }}</span>
            </a>
          </div>
        </perfect-scrollbar>
        <div
          v-if="combinedGoods.length > 1"
          class="product-combined"
          v-bind:class="'count-' + combinedGoods.length"
        >
          <h3>{{ locale.TEXTS.combinedGoodsText }}:</h3>
          <swiper
            class="swiper"
            :slides-per-view="
              combinedGoods.length > 4 ? 4 : combinedGoods.length
            "
            :space-between="10"
            :modules="modules"
            :allow-touch-move="false"
            :navigation="combinedGoods.length > 4 ? true : false"
            :initialSlide="combinedInitialSlide"
            :style="{
              '--swiper-navigation-color': '#000',
              '--swiper-navigation-size': '32px',
            }"
          >
            <swiper-slide
              v-for="item in combinedGoods"
              :key="item"
              class="good-item"
              :class="{ active: product.id === item.id }"
              @click="locationHref(item.shop_page[0].url)"
            >
              <a>
                <span class="text">{{ item.text }}</span>
                <img v-if="item.photo" :src="item.photo.image_object_small" />
                <img v-else :src="item.images[0].image.image_object_small" />
                <span class="discount" v-if="item.discount">
                  {{ item.discount }}
                </span>
              </a>
            </swiper-slide>
          </swiper>
        </div>
        <div
          v-if="product.type === 4 && giftCardTypeSelected === 0"
          class="product-gift-card"
        >
          <h3>Выберите тип карты:</h3>
          <div
            class="gift-card-item"
            @click.stop="selectGiftCardType(6)"
            :class="{ active: giftCardTypeActive === 6 }"
          >
            <img
              v-if="product.photo_virtual"
              :src="product.photo_virtual.image_object_small"
            />
            <span>
              <span>Цифровая карта</span>
              <span>Можно подарить даже на расстоянии </span>
            </span>
          </div>
          <div
            class="gift-card-item"
            @click.stop="selectGiftCardType(5)"
            v-if="realGiftCardNominals.length > 0"
            :class="{ active: giftCardTypeActive === 5 }"
          >
            <img
              v-if="product.photo_real"
              :src="product.photo_real.image_object_small"
            />
            <span>
              <span>Пластиковая карта</span>
              <span>Можно подарить лично в руки</span>
            </span>
          </div>
        </div>
        <div
          v-if="giftCardTypeSelected === 5 && giftCardNominalSelected === null"
          class="product-gift-card"
        >
          <h3>Выберите номинал:</h3>
          <div
            v-for="item in realGiftCardNominals"
            :key="item"
            class="gift-card-item"
            @click.stop="selectGiftCardNominal(item)"
            :class="{ active: giftCardNominalActive === item.id }"
          >
            <span>
              <span>{{ item.headline_preview }} руб.</span>
              <span>+ {{ item.bonus }} бонусов на следующую покупку</span>
            </span>
          </div>
        </div>
        <div
          v-if="giftCardTypeSelected === 6 && giftCardNominalSelected === null"
          class="product-gift-card"
        >
          <h3>Выберите номинал:</h3>
          <div
            v-for="item in virtualGiftCardNominals"
            :key="item"
            class="gift-card-item"
            @click.stop="selectGiftCardNominal(item)"
            :class="{ active: giftCardNominalActive === item.id }"
          >
            <span>
              <span>{{ item.headline_preview }} руб.</span>
              <span>+ {{ item.bonus }} бонусов на следующую покупку</span>
            </span>
          </div>
        </div>
        <div
          v-if="
            giftCardTypeSelected === 6 &&
            giftCardNominalSelected !== null &&
            giftCardCommentSelected === null
          "
          class="product-gift-card-comment"
        >
          <h3>Добавьте поздравление:</h3>
          <textarea
            v-model="giftCardComment"
            placeholder="Напишите событие и пожелайте чего-то приятного"
          ></textarea>
          <div class="buttons">
            <button @click.stop="giftCardCommentSelected = ''">
              Пропустить
            </button>
            <button @click.stop="giftCardCommentSelected = giftCardComment">
              Продолжить
            </button>
          </div>
        </div>
        <div
          class="product-basket"
          v-if="giftCardTypeSelected === 5 && giftCardNominalSelected"
        >
          <span
            v-if="locale.LOCALE === 'ru'"
            class="favorite"
            @click.stop="clickFavoriteProduct(product)"
            :class="
              'favorite-page-' +
              product.id +
              ' favorite-' +
              product.id +
              ' ' +
              product.isFavorite
            "
          ></span>
          <div class="price">
            <span class="new">
              {{ formatPrice(giftCardNominalSelected.headline_preview) }}
              {{ locale.TEXTS.currencyText }}
            </span>
            <button
              v-if="giftCardNominalSelected.productBasket > 0"
              @click="nextOnCart"
            >
              <span>
                В корзине {{ giftCardNominalSelected.productBasket }} шт.
              </span>
              <span>Перейти</span>
            </button>
            <button
              v-else
              @click.stop="openBasketProductPopup(giftCardNominalSelected)"
              :class="'basket-page-' + product.id + ' basket-' + product.id"
            >
              {{ locale.TEXTS.toBasketText }}
            </button>
          </div>
          <span v-if="giftCardNominalSelected.bonus > 0" class="benefit">
            + {{ giftCardNominalSelected.bonus }}
            {{ getTextBonus(giftCardNominalSelected.bonus) }}
            {{ locale.TEXTS.bonusBuy }}
          </span>
          <div
            v-if="giftCardNominalSelected.productBasket > 0"
            class="product-basket-active"
          >
            <span
              class="minus"
              @click.stop="
                clickBasketProduct(
                  giftCardNominalSelected,
                  giftCardNominalSelected.productBasket - 1,
                  false,
                  1,
                  null
                )
              "
            ></span>
            <button @click="nextOnCart">
              <span>
                В корзине {{ giftCardNominalSelected.productBasket }} шт.
              </span>
              <span>Перейти</span>
            </button>
            <span
              class="plus"
              @click.stop="
                clickBasketProduct(
                  giftCardNominalSelected,
                  giftCardNominalSelected.productBasket + 1,
                  true,
                  1,
                  null
                )
              "
              :class="'basket-page-' + product.id + ' basket-' + product.id"
            ></span>
          </div>
          <button
            v-else
            @click.stop="openBasketProductPopup(giftCardNominalSelected)"
            :class="'basket-page-' + product.id + ' basket-' + product.id"
          >
            {{ locale.TEXTS.addBasketText }}
          </button>
          <hr v-if="locale.LOCALE === 'ru'" />
          <ListDeliveryBlock v-if="locale.LOCALE === 'ru'" />
        </div>
        <div
          class="product-gift-card-comment"
          v-if="
            giftCardTypeSelected === 6 &&
            giftCardNominalSelected &&
            giftCardCommentSelected
          "
        >
          <div class="value" @click.stop="giftCardCommentSelected = null">
            {{ giftCardCommentSelected }}
          </div>
        </div>
        <div
          class="product-basket"
          v-if="
            giftCardTypeSelected === 6 &&
            giftCardNominalSelected &&
            giftCardCommentSelected !== null
          "
        >
          <span
            v-if="locale.LOCALE === 'ru'"
            class="favorite"
            @click.stop="clickFavoriteProduct(product)"
            :class="
              'favorite-page-' +
              product.id +
              ' favorite-' +
              product.id +
              ' ' +
              product.isFavorite
            "
          ></span>
          <div class="price">
            <span class="new">
              {{ formatPrice(giftCardNominalSelected.headline_preview) }}
              {{ locale.TEXTS.currencyText }}
            </span>
            <button
              v-if="giftCardNominalSelected.productBasket > 0"
              @click="nextOnCart"
            >
              <span>
                В корзине {{ giftCardNominalSelected.productBasket }} шт.
              </span>
              <span>Перейти</span>
            </button>
            <button
              v-else
              @click.stop="openBasketProductPopup(giftCardNominalSelected)"
              :class="'basket-page-' + product.id + ' basket-' + product.id"
            >
              {{ locale.TEXTS.toBasketText }}
            </button>
          </div>
          <span v-if="giftCardNominalSelected.bonus > 0" class="benefit">
            + {{ giftCardNominalSelected.bonus }}
            {{ getTextBonus(giftCardNominalSelected.bonus) }}
            {{ locale.TEXTS.bonusBuy }}
          </span>
          <div
            v-if="giftCardNominalSelected.productBasket > 0"
            class="product-basket-active"
          >
            <span
              class="minus"
              @click.stop="
                clickBasketProduct(
                  giftCardNominalSelected,
                  giftCardNominalSelected.productBasket - 1,
                  false,
                  1,
                  null
                )
              "
            ></span>
            <button @click="nextOnCart">
              <span>
                В корзине {{ giftCardNominalSelected.productBasket }} шт.
              </span>
              <span>Перейти</span>
            </button>
            <span
              class="plus"
              @click.stop="
                clickBasketProduct(
                  giftCardNominalSelected,
                  giftCardNominalSelected.productBasket + 1,
                  true,
                  1,
                  null
                )
              "
              :class="'basket-page-' + product.id + ' basket-' + product.id"
            ></span>
          </div>
          <button
            v-else
            @click.stop="openBasketProductPopup(giftCardNominalSelected)"
            :class="'basket-page-' + product.id + ' basket-' + product.id"
          >
            {{ locale.TEXTS.addBasketText }}
          </button>
        </div>
        <div class="product-basket" v-if="product.type !== 4">
          <span
            v-if="locale.LOCALE === 'ru'"
            class="favorite"
            @click.stop="clickFavoriteProduct(product)"
            :class="
              'favorite-page-' +
              product.id +
              ' favorite-' +
              product.id +
              ' ' +
              product.isFavorite
            "
          ></span>
          <div class="price">
            <span class="new">
              {{ formatPrice(productPrice) }} {{ locale.TEXTS.currencyText }}
            </span>
            <span v-if="productPriceOld" class="old">
              {{ formatPrice(productPriceOld) }} {{ locale.TEXTS.currencyText }}
            </span>
            <button v-if="product.productBasket > 0" @click="nextOnCart">
              <span>В корзине {{ product.productBasket }} шт.</span>
              <span>Перейти</span>
            </button>
            <button
              v-else
              @click.stop="openBasketProductPopup(product)"
              :class="'basket-page-' + product.id + ' basket-' + product.id"
            >
              {{ locale.TEXTS.toBasketText }}
            </button>
          </div>
          <span v-if="productPriceOld" class="benefit">
            {{ locale.TEXTS.yourBenefit }}
            {{ productPercent }} % ({{ formatPrice(productAmount) }}
            {{ locale.TEXTS.currencyText }})
          </span>
          <span v-if="product.bonus > 0" class="benefit">
            + {{ product.bonus }} {{ getTextBonus(product.bonus) }}
            {{ locale.TEXTS.bonusBuy }}
          </span>
          <div v-if="product.productBasket > 0" class="product-basket-active">
            <span
              class="minus"
              @click.stop="
                clickBasketProduct(
                  product,
                  product.productBasket - 1,
                  false,
                  1,
                  null
                )
              "
            ></span>
            <button @click="nextOnCart">
              <span>В корзине {{ product.productBasket }} шт.</span>
              <span>Перейти</span>
            </button>
            <span
              class="plus"
              @click.stop="
                clickBasketProduct(
                  product,
                  product.productBasket + 1,
                  true,
                  1,
                  null
                )
              "
              :class="'basket-page-' + product.id + ' basket-' + product.id"
            ></span>
          </div>
          <button
            v-else
            @click.stop="openBasketProductPopup(product)"
            :class="'basket-page-' + product.id + ' basket-' + product.id"
          >
            {{ locale.TEXTS.addBasketText }}
          </button>
          <hr v-if="locale.LOCALE === 'ru'" />
          <ListDeliveryBlock v-if="locale.LOCALE === 'ru'" />
        </div>
      </div>
    </div>
    <div class="product-tabs">
      <div>
        <h3
          :class="{ active: openBlock === 'description' }"
          @click="openBlock = 'description'"
        >
          {{ locale.TEXTS.description }}
        </h3>
        <h3
          :class="{ active: openBlock === 'feedback' }"
          @click="openBlock = 'feedback'"
        >
          {{ locale.TEXTS.feedbacks }}
          <span>{{ countFeedback }}</span>
        </h3>
        <h3
          :class="{ active: openBlock === 'questions' }"
          @click="openBlock = 'questions'"
        >
          {{ locale.TEXTS.questions }}
          <span>{{ countQuestions }}</span>
        </h3>
      </div>
    </div>
    <div class="product-info">
      <div :class="{ 'd-none': openBlock !== 'description' }">
        <h2 class="description">{{ locale.TEXTS.description }}:</h2>
        <div class="product-description">
          <EditorBlock
            v-if="product"
            :page="product"
            :isProduct="true"
            :sourcePage="1"
          />
        </div>
        <div v-if="product.type === 3">
          <h2>{{ locale.TEXTS.composition }}:</h2>
          <perfect-scrollbar :options="optionsPS" :watch-options="false">
            <div class="components-block">
              <div v-for="item in product.products" :key="item">
                <div class="component-header">
                  <span>
                    <span>{{ item.product.headline_preview }}:</span>
                    {{ locale.TEXTS.maintenance }} (
                    {{ item.dosage }}
                    {{ item.product.release_form.name }})
                  </span>
                  <span>{{ locale.TEXTS.maintenancePercent }}</span>
                </div>
                <div v-for="component in item.components" :key="component">
                  <div class="component-item">
                    <span @click="component.isOpen = true">
                      <span>
                        {{ component.composition_component.component.name }}
                      </span>
                    </span>
                    <span>{{ component.quantity }}</span>
                    <span>
                      {{ component.percentage }}
                      <span>{{ component.footnote }}</span>
                    </span>
                  </div>
                  <div
                    v-if="component.isOpen"
                    class="component-popup shop-popup"
                  >
                    <div class="content">
                      <img
                        @click="component.isOpen = false"
                        src="@/assets/images/shop-close.svg"
                        class="close"
                      />
                      <h5>
                        {{ component.composition_component.component.name }}
                      </h5>
                      <p>
                        {{
                          component.composition_component.component.description
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
          <span class="product-explanation-of-the-composition">
            {{ product.explanation_of_the_composition }}
          </span>
          <span class="show-explanation">
            {{ locale.TEXTS.showExplanation }}
          </span>
        </div>
        <div v-else-if="product.type === 2">
          <h2>{{ locale.TEXTS.composition }}:</h2>
          <perfect-scrollbar :options="optionsPS" :watch-options="false">
            <div class="components-block">
              <div v-for="item in product.products" :key="item">
                <div class="component-header">
                  <span>
                    {{ locale.TEXTS.maintenance }} (
                    {{ item.product.dosage }}
                    {{ item.product.release_form.name }})
                  </span>
                  <span>{{ locale.TEXTS.maintenancePercent }}</span>
                </div>
                <div
                  v-for="component in item.product.components"
                  :key="component"
                >
                  <div class="component-item">
                    <span @click="component.isOpen = true">
                      <span>
                        {{ component.component.name }}
                      </span>
                    </span>
                    <span>{{ component.quantity }}</span>
                    <span>
                      {{ component.percentage }}
                      <span>{{ component.footnote }}</span>
                    </span>
                  </div>
                  <div
                    v-if="component.isOpen"
                    class="component-popup shop-popup"
                  >
                    <div class="content">
                      <img
                        @click="component.isOpen = false"
                        src="@/assets/images/shop-close.svg"
                        class="close"
                      />
                      <h5>
                        {{ component.component.name }}
                      </h5>
                      <p>
                        {{ component.component.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
          <span class="product-explanation-of-the-composition">
            {{ product.explanation_of_the_composition }}
          </span>
          <span class="show-explanation">
            {{ locale.TEXTS.showExplanation }}
          </span>
        </div>
        <div v-else-if="product.components.length > 0">
          <h2>{{ locale.TEXTS.composition }}:</h2>
          <perfect-scrollbar :options="optionsPS" :watch-options="false">
            <div class="components-block">
              <div class="component-header">
                <span>
                  {{ locale.TEXTS.maintenance }} (
                  {{ product.dosage }}
                  {{ product.release_form.name }})
                </span>
                <span>{{ locale.TEXTS.maintenancePercent }}</span>
              </div>
              <div v-for="item in product.components" :key="item">
                <div class="component-item">
                  <span @click="item.isOpen = true">
                    <span>{{ item.component.name }}</span>
                  </span>
                  <span>{{ item.quantity }}</span>
                  <span>
                    {{ item.percentage }}
                    <span>{{ item.footnote }}</span>
                  </span>
                </div>
                <div v-if="item.isOpen" class="component-popup shop-popup">
                  <div class="content">
                    <img
                      @click="item.isOpen = false"
                      src="@/assets/images/shop-close.svg"
                      class="close"
                    />
                    <h5>{{ item.component.name }}</h5>
                    <p>{{ item.component.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
          <span class="product-explanation-of-the-composition">
            {{ product.explanation_of_the_composition }}
          </span>
          <span class="show-explanation">
            {{ locale.TEXTS.showExplanation }}
          </span>
        </div>
        <h2 v-if="product.type !== 4" class="instruction">
          {{ locale.TEXTS.instruction }}:
        </h2>
        <p v-if="product.type !== 4">{{ product.instruction }}</p>
        <div v-if="product.release_form">
          <h2>{{ locale.TEXTS.filterReleaseForm }}:</h2>
          <p>{{ product.release_form.name }}</p>
        </div>
        <div v-if="product.documents.length > 0">
          <h2>{{ locale.TEXTS.documents }}:</h2>
          <p
            class="document-item"
            v-for="(item, index) in product.documents"
            :key="item"
          >
            <a @click="(isOpenPopupDocuments = true), (initialSlide = index)">
              {{ item.name }}
            </a>
          </p>
        </div>
      </div>
      <div :class="{ 'd-none': openBlock !== 'feedback' }">
        <div class="product-feedback" :class="{ empty: countFeedback === 0 }">
          <span class="eval" v-if="countFeedback">{{ evalFeedback }}</span>
          <span class="rating" v-if="locale.LOCALE === 'ru'">
            <span class="stars" v-if="countFeedback" v-html="starsFeedback">
            </span>
            <span class="text" v-if="countFeedback">
              На основании {{ countFeedback }} отзывов
            </span>
            <span class="text" v-else>
              Еще никто не оставил отзыв.<br />
              Будьте первым, кто напишет отзыв!
            </span>
          </span>
          <span class="rating" v-else>
            <span class="stars" v-if="countFeedback" v-html="starsFeedback">
            </span>
            <span class="text" v-if="countFeedback">
              Based on {{ countFeedback }} feedback
            </span>
            <span class="text" v-else>No feddback</span>
          </span>
          <button v-if="locale.LOCALE === 'ru'" @click="openAddFeedbackBlock">
            Оставить отзыв
          </button>
          <a v-if="countFeedback" :href="'/catalog/' + slug + '/feedback'">
            {{ locale.TEXTS.feedbackAll }}
          </a>
        </div>
        <div v-for="item in feedback" :key="item" class="product-feedback-item">
          <ProductFeedbackItemBlock
            :review="item"
            :valueAllowTouchMove="valueAllowTouchMove"
            :slidesPerView="8"
            :spaceBetween="10"
          />
          <div
            v-if="item.count_likes > 0 && locale.LOCALE === 'ru'"
            class="likes"
            :class="{ active: item.isMyLike }"
            @click="clickFeedbackLike(item)"
          >
            <img
              v-if="item.isMyLike"
              src="@/assets/images/shop-like-active.svg"
            />
            <img v-else src="@/assets/images/shop-like-gray.svg" />
            {{ item.count_likes }}
          </div>
          <div
            v-else-if="!item.isMy && locale.LOCALE === 'ru'"
            class="likes"
            @click="clickFeedbackLike(item)"
          >
            <img src="@/assets/images/shop-like-gray.svg" />
            Полезный отзыв?
          </div>
          <div v-else class="likes">
            <img src="@/assets/images/shop-like-gray.svg" />
            {{ item.count_likes }}
          </div>
        </div>
        <a
          v-if="countFeedback > 0"
          class="show-all-feedback"
          :href="'/catalog/' + slug + '/feedback'"
        >
          {{ locale.TEXTS.feedbackDisplayAll }}
        </a>
      </div>
      <div :class="{ 'd-none': openBlock !== 'questions' }">
        <div class="product-question">
          <span class="count" v-if="countQuestions">{{ countQuestions }}</span>
          <span class="text">
            <span
              v-if="countQuestions"
              v-html="locale.TEXTS.countQuestionsText"
            ></span>
            <span v-else-if="locale.LOCALE === 'ru'">
              Еще никто не задал вопрос.<br />
              Будьте первым, кто задаст вопрос!
            </span>
            <span v-else>No questions</span>
          </span>
          <button v-if="locale.LOCALE === 'ru'" @click="openAddQuestionBlock">
            Задать вопрос
          </button>
          <a v-if="countQuestions" :href="'/catalog/' + slug + '/questions'">
            {{ locale.TEXTS.questionsAll }}
          </a>
        </div>
        <div
          class="product-question-item"
          v-for="item in questions"
          :key="item"
          :class="{ open: item.isOpen }"
        >
          <span
            class="button"
            @click="item.isOpen ? (item.isOpen = false) : (item.isOpen = true)"
          ></span>
          <span
            @click="item.isOpen ? (item.isOpen = false) : (item.isOpen = true)"
            class="text"
          >
            {{ item.text }}
          </span>
          <p>{{ item.answer }}</p>
        </div>
        <a
          v-if="countQuestions > 0"
          class="show-all-questions"
          :href="'/catalog/' + slug + '/questions'"
        >
          {{ locale.TEXTS.questionsDisplayAll }}
        </a>
      </div>
    </div>

    <div class="product-h3-block">
      <a
        @click="
          $router.push({
            name: 'product_feedback',
            params: {
              path_catalog: slug,
            },
          })
        "
        :class="'count-' + countFeedback"
      >
        <span class="title">{{ locale.TEXTS.feedbacks }}</span>
        <span class="count">{{ countFeedback }}</span>
        <span class="rating" v-if="countFeedback">
          <span class="stars" v-html="starsFeedback"></span>
          <span class="text">{{ countFeedback }}</span>
        </span>
        <button
          v-else-if="locale.LOCALE === 'ru'"
          @click.stop="openAddFeedbackBlock"
        >
          Отправить отзыв
        </button>
      </a>
      <a
        @click="
          $router.push({
            name: 'product_questions',
            params: {
              path_catalog: slug,
            },
          })
        "
        :class="'count-' + questions.length"
      >
        <span class="title">{{ locale.TEXTS.questions }}</span>
        <span class="count">{{ questions.length }}</span>
        <button
          @click.stop="openAddQuestionBlock"
          v-if="questions.length === 0 && locale.LOCALE === 'ru'"
        >
          Задать вопрос
        </button>
      </a>
    </div>

    <div class="product-recommended" v-if="recommendGoods.length > 0">
      <h2>{{ product.recommend_headline }}</h2>
      <div class="product-recommended-goods">
        <div
          class="product-recommended-goods-items"
          v-bind:class="'count-' + recommendGoods.length"
        >
          <a
            v-for="item in recommendGoods"
            :key="item"
            :href="'/catalog/' + item.product.shop_page[0].url"
          >
            <span
              class="image"
              :style="{
                background: item.product.images[0].image.background_color,
              }"
            >
              <img :src="item.product.images[0].image.image_object_middle" />
            </span>
            <span v-if="item.discount_price" class="price">
              <span class="new">
                {{ formatPrice(item.discount_price) }}
                {{ locale.TEXTS.currencyText }}
              </span>
              <span class="old">
                {{ formatPrice(item.product.website_price) }}
                {{ locale.TEXTS.currencyText }}
              </span>
            </span>
            <span v-else class="price">
              <span class="new">
                {{ formatPrice(item.product.website_price) }}
                {{ locale.TEXTS.currencyText }}
              </span>
            </span>
            <span class="name">
              {{ item.product.headline_preview }}
            </span>
          </a>
        </div>
        <div
          v-for="item in recommendGoods"
          :key="item"
          class="product-recommended-goods-item"
        >
          <span>
            {{ item.product.headline_preview }}
          </span>
          <span>х1 {{ locale.TEXTS.thing }}</span>
        </div>
        <div class="product-recommended-good-cart">
          <div class="benefit" v-if="benefitNew === benefitOld">
            <div class="price">
              <span class="new">
                {{ formatPrice(benefitNew) }} {{ locale.TEXTS.currencyText }}
              </span>
            </div>
          </div>
          <div class="benefit" v-else>
            <div class="price">
              <span class="new">
                {{ formatPrice(benefitNew) }} {{ locale.TEXTS.currencyText }}
              </span>
              <span class="old">
                {{ formatPrice(benefitOld) }} {{ locale.TEXTS.currencyText }}
              </span>
            </div>
            <span>
              {{ locale.TEXTS.yourBenefit }} {{ benefitPercent }} % ({{
                formatPrice(benefitAmount)
              }}
              {{ locale.TEXTS.currencyText }})
            </span>
          </div>
          <div
            v-if="recommendProduct.productBasket > 0"
            class="product-basket-active"
          >
            <span
              class="minus"
              @click.stop="
                clickBasketProduct(
                  recommendProduct,
                  recommendProduct.productBasket - 1,
                  false,
                  7,
                  null
                )
              "
            ></span>
            <button @click="nextOnCart">
              <span>В корзине {{ recommendProduct.productBasket }} шт.</span>
              <span>Перейти</span>
            </button>
            <span
              class="plus"
              @click.stop="
                clickBasketProduct(
                  recommendProduct,
                  recommendProduct.productBasket + 1,
                  true,
                  7,
                  null
                )
              "
            ></span>
          </div>
          <button
            v-else
            @click.stop="clickBasketProduct(recommendProduct, 1, true, 7, null)"
          >
            {{ locale.TEXTS.complexBasketText }}
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="isOpenPopupGallery"
      class="gallery-popup shop-popup unselectable"
    >
      <div class="content">
        <span class="close" @click="isOpenPopupGallery = null">
          <img src="@/assets/images/shop-close.svg" />
        </span>
        <swiper
          class="swiper"
          :modules="modules"
          :slides-per-view="1"
          :initialSlide="initialSlide"
          :loop="true"
          :pagination="{ type: 'bullets' }"
          :navigation="product.images.length > 1 ? true : false"
          :allow-touch-move="valueAllowTouchMove"
          :keyboard="{ enabled: true }"
          :style="{
            '--swiper-navigation-color': '#000',
            '--swiper-navigation-size': '32px',
          }"
        >
          <swiper-slide
            v-for="item in product.images"
            :key="item"
            :style="{ background: item.image.background_color }"
            :class="{ zoom: item.isZoom }"
          >
            <span
              class="image-item"
              @mousemove="
                (event) => cursorGallery(event, product.images.length)
              "
              @click="(event) => clickNextGallery(event)"
            >
              <img
                :src="item.image.image_object"
                @click.stop="
                  item.isZoom ? (item.isZoom = false) : (item.isZoom = true)
                "
              />
            </span>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>
  <div v-if="isOpenPopupBasketProduct" class="basket-product-popup shop-popup">
    <div class="content">
      <img
        @click="isOpenPopupBasketProduct = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <h5 class="desktop">Товар добавлен<br />в корзину</h5>
      <h5 class="mobile">Добавлен в корзину</h5>
      <div class="product">
        <div
          class="image"
          :style="{
            background: product.images[0].image.background_color,
          }"
        >
          <img :src="product.images[0].image.image_object_middle" />
        </div>
        <div class="info">
          <span class="price" v-if="product.type === 4">
            <span class="new">Цена по номиналу</span>
          </span>
          <span class="price" v-else>
            <span class="new">{{ formatPrice(productPrice) }} руб.</span>
            <span v-if="productPriceOld" class="old">
              {{ formatPrice(productPriceOld) }} руб.
            </span>
          </span>
          <span class="name">{{ product.headline_preview }}</span>
          <span class="quantity" v-if="product.type !== 4">
            {{ product.feature_product_quantity }}
            <span v-if="product.release_form">
              {{
                getDeclination(
                  product.feature_product_quantity,
                  product.release_form
                )
              }}
            </span>
          </span>
        </div>
      </div>
      <div class="buttons">
        <button @click="nextOnCart">Перейти в корзину</button>
        <a @click="isOpenPopupBasketProduct = false">Продолжить покупки</a>
      </div>
    </div>
  </div>
  <div
    v-if="isOpenPopupDocuments"
    class="documents-popup shop-popup unselectable"
  >
    <div class="content">
      <span class="close" @click="isOpenPopupDocuments = null">
        <img src="@/assets/images/shop-close.svg" />
      </span>
      <swiper
        class="swiper"
        :modules="modules"
        :slides-per-view="1"
        :initialSlide="initialSlide"
        :loop="true"
        :pagination="{ type: 'bullets' }"
        :navigation="product.documents.length > 1 ? true : false"
        :allow-touch-move="valueAllowTouchMove"
        :keyboard="{ enabled: true }"
        :style="{
          '--swiper-navigation-color': '#000',
          '--swiper-navigation-size': '32px',
        }"
      >
        <swiper-slide v-for="item in product.documents" :key="item">
          <iframe
            :src="
              'https://docs.google.com/viewerng/viewer?url=' +
              item.file.file_object +
              '&embedded=true'
            "
          ></iframe>
        </swiper-slide>
      </swiper>
    </div>
  </div>
  <ErrorTooltipBlock
    v-if="errorTooltip === 'available'"
    :header="'Ошибка'"
    :text="'Недостаточно в наличие'"
  />
  <ErrorTooltipBlock
    v-if="errorTooltip === 'like'"
    :header="'Ошибка'"
    :text="'Вы не можете поднять рейтинг своего отзыва. Это могут сделать только другие пользователи.'"
  />
  <FooterMinBlock />
  <AuthRegBlock
    v-if="isOpenAuth && locale.LOCALE === 'ru'"
    :authText="authText"
  />
  <ProfileNameBlock
    v-if="isOpenProfileName"
    :profile="profile"
    :isCheckout="false"
    :isPartner="false"
    :isLastName="false"
  />
  <NotOrderBlock v-if="isOpenNotOrder" />
  <ExistFeedbackBlock v-if="isOpenExistFeedback" />
  <AddFeedbackBlock
    v-if="isOpenAddFeedback && locale.LOCALE === 'ru'"
    :product="product"
  />
  <AddQuestionBlock
    v-if="isOpenAddQuestion && locale.LOCALE === 'ru'"
    :product="product"
  />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import pageMixin from "@/mixins/pageMixin";
import productMixin from "@/mixins/productMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterMinBlock from "@/components/FooterMinBlock.vue";
import ListDeliveryBlock from "@/components/ListDeliveryBlock.vue";
import AuthRegBlock from "@/components/AuthRegBlock.vue";
import ProfileNameBlock from "@/components/ProfileNameBlock.vue";
import NotOrderBlock from "@/components/NotOrderBlock.vue";
import ExistFeedbackBlock from "@/components/ExistFeedbackBlock.vue";
import AddFeedbackBlock from "@/components/AddFeedbackBlock.vue";
import AddQuestionBlock from "@/components/AddQuestionBlock.vue";
import ProductFeedbackItemBlock from "@/components/ProductFeedbackItemBlock.vue";
import ErrorTooltipBlock from "@/components/ErrorTooltipBlock.vue";
import EditorBlock from "@/components/EditorBlock.vue";
import { Pagination, Navigation, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import "@/assets/css/product.css";
import "@/assets/css/blog.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import api from "@/api";
import Locale from "@/api/locale";

export default {
  mixins: [mainMixin, pageMixin, productMixin],
  components: {
    HeaderBlock,
    FooterMinBlock,
    ListDeliveryBlock,
    AuthRegBlock,
    ProfileNameBlock,
    NotOrderBlock,
    ExistFeedbackBlock,
    AddFeedbackBlock,
    AddQuestionBlock,
    ProductFeedbackItemBlock,
    ErrorTooltipBlock,
    EditorBlock,
    Swiper,
    SwiperSlide,
  },
  props: {
    productProp: Object,
    slug: String,
    profile: Object,
  },
  setup() {
    return {
      modules: [Pagination, Navigation, Keyboard],
    };
  },
  data() {
    return {
      product: {},
      locale: Locale,
      isOpenAuth: false,
      isOpenProfileName: false,
      isOpenNotOrder: false,
      isOpenExistFeedback: false,
      isOpenAddFeedback: false,
      isOpenAddQuestion: false,
      recommendProduct: null,
      recommendProductId: null,
      benefitNew: 0,
      benefitOld: 0,
      benefitPercent: 0,
      benefitAmount: 0,
      productPercent: 0,
      productAmount: 0,
      evalFeedback: 0,
      countFeedback: 0,
      textFeedback: "",
      starsFeedback: "",
      countQuestions: 0,
      isOpenPopupGallery: null,
      openBlock: "description",
      questions: [],
      feedback: [],
      valueAllowTouchMove: true,
      isOpenPopupDocuments: false,
      productPrice: null,
      productPriceOld: null,
      initialSlide: 0,
      recommendGoods: [],
      combinedGoods: [],
      optionsPS: {
        suppressScrollY: true,
      },
      isExistFeedback: false,
      combinedInitialSlide: 0,
      pageBack: "/catalog",
      errorTooltip: null,
      isOpenPopupBasketProduct: false,
      searchSessionHash: null,
      giftCardTypeActive: 0,
      giftCardTypeSelected: 0,
      realGiftCardNominals: [],
      virtualGiftCardNominals: [],
      giftCardNominalActive: null,
      giftCardNominalSelected: null,
      giftCardComment: "",
      giftCardCommentSelected: null,
    };
  },
  created() {
    let referrer = document.referrer;
    if (referrer && referrer !== "" && referrer.indexOf("?sort=") !== -1) {
      this.pageBack = referrer;
    }
    this.product = this.productProp;
    if (this.product.type === 2) {
      let product = this.product.products[0].product;
      this.product.blocks = product.blocks;
      this.product.instruction = product.instruction;
      this.product.explanation_of_the_composition =
        product.explanation_of_the_composition;
      this.product.documents = product.documents;
    }
    if (this.product.type === 4) {
      this.getGiftCardNominals();
    }
    if (window.innerWidth >= 1200) {
      this.valueAllowTouchMove = false;
    }
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        that.isOpenAuth = false;
        that.isOpenProfileName = false;
        that.isOpenNotOrder = false;
        that.isOpenExistFeedback = false;
        that.isOpenAddFeedback = false;
        that.isOpenAddQuestion = false;
        that.isOpenPopupGallery = false;
        that.isOpenPopupDocuments = false;
        that.isOpenPopupBasketProduct = false;
        for (let item in that.product.products) {
          item = that.product.products[item];
          for (let component in item.components) {
            item.components[component].isOpen = false;
          }
        }
        for (let item in that.product.components) {
          that.product.components[item].isOpen = false;
        }
        that.isOpenPopup(false);
      }
    });
  },
  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("searchSessionHash")) {
      this.searchSessionHash = urlParams.get("searchSessionHash");
      let payload = {
        search_session_hash: this.searchSessionHash,
        product_id: this.product.id,
      };
      sessionStorage.setItem("searchSessionHash", this.searchSessionHash);
      try {
        await api.shop.postShopSearchSessionClickProduct(payload);
      } catch (error) {
        console.log(error);
      }
    }
    this.productPrice = this.product.website_price;
    if (this.product.discount_price) {
      this.productPrice = this.product.discount_price;
      this.productPriceOld = this.product.website_price;
    }
    this.benefitNew += this.productPrice;
    if (this.productPriceOld) {
      this.benefitOld += this.productPriceOld;
      this.productAmount = this.benefitOld - this.benefitNew;
      this.productPercent = this.benefitNew * 100;
      this.productPercent = this.productPercent / this.benefitOld;
      this.productPercent = Math.round(100 - this.productPercent);
    } else this.benefitOld += this.productPrice;

    if (this.product.recommend_product_id) {
      this.recommendProductId = this.product.recommend_product_id;
      this.recommendProduct = this.product.recommend_product;
      this.benefitOld = this.recommendProduct.website_price;
      this.benefitNew = this.benefitOld;
      if (this.recommendProduct.discount_price) {
        this.benefitNew = this.recommendProduct.discount_price;
      }
      this.benefitAmount = this.benefitOld - this.benefitNew;
      let discount_price = 0;
      if (this.benefitAmount > 0) {
        discount_price =
          this.benefitAmount / this.recommendProduct.products.length;
      }
      for (let item in this.recommendProduct.products) {
        item = this.recommendProduct.products[item];
        if (discount_price > 0) {
          item.discount_price = item.product.website_price - discount_price;
        }
        this.recommendGoods.push(item);
      }
      this.benefitPercent = this.benefitNew * 100;
      this.benefitPercent = this.benefitPercent / this.benefitOld;
      this.benefitPercent = Math.round(100 - this.benefitPercent);
      this.getBasketProduct(this.recommendProduct);
    }
    let num = 0;
    let initialSlide = 0;
    if (this.product.combined_goods.length > 0) {
      let goods = this.product.combined_goods[0]["combined_goods"]["goods"];
      for (let item in goods) {
        let product = goods[item]["product"];
        if (product["is_deleted"]) continue;
        if (product["discount_price"]) {
          product["discount"] = product["discount_price"] * 100;
          product["discount"] = product["discount"] / product["website_price"];
          product["discount"] = Math.round(100 - product["discount"]);
          product["discount"] = "-" + product["discount"] + " %";
        }
        product["text"] = goods[item]["text"];
        if (product["id"] === this.product.id) {
          initialSlide = num;
        }
        num += 1;
        this.combinedGoods.push(product);
      }
      if (initialSlide > 2) this.combinedInitialSlide = initialSlide - 2;
    }

    try {
      const result = await api.shop.getProductFeedback(this.product.id, 50);
      this.countFeedback = result["data"]["results"].length;
      this.textFeedback = this.countFeedback.toString();
      if (this.countFeedback > 4 && this.countFeedback < 21) {
        this.textFeedback = Locale.TEXTS.feedbackMany;
      } else if (this.textFeedback.slice(-1) === "1") {
        this.textFeedback = Locale.TEXTS.feedbackOne;
      } else if (this.textFeedback.slice(-1) === "2") {
        this.textFeedback = Locale.TEXTS.feedbackTwo;
      } else if (this.textFeedback.slice(-1) === "3") {
        this.textFeedback = Locale.TEXTS.feedbackTwo;
      } else if (this.textFeedback.slice(-1) === "4") {
        this.textFeedback = Locale.TEXTS.feedbackTwo;
      } else this.textFeedback = Locale.TEXTS.feedbackMany;
      let count = 0;
      let eval_count = 0;
      for (let item in result["data"]["results"]) {
        item = result["data"]["results"][item];
        eval_count += item["eval"];
        if (count < 5) {
          count += 1;
          let avatar = null;
          let isMy = false;
          let name = item["client_name"];
          if (item["client_avatar"]) {
            avatar = item["client_avatar"]["image_object"];
          }
          if (item["client_info"]) {
            if (item["client_info"]["avatar"]) {
              avatar = item["client_info"]["avatar"]["image_object"];
            }
            name = item["client_info"]["first_name"];
            if (item["client_info"]["id"] === this.profile.id) {
              isMy = true;
            }
          }
          let date = item["created_at"].split("T")[0].split("-");
          let text = item["text_edited"];
          if (!text) text = item["text_origin"];
          let isMyLike = false;
          if (this.authToken && item["user_likes"].includes(this.profile.id)) {
            isMyLike = true;
          }
          this.feedback.push({
            id: item["id"],
            avatar: avatar,
            name: name,
            date: date[2] + "." + date[1] + "." + date[0],
            content: text,
            images: item["images"],
            count_likes: item["count_likes"],
            stars: this.getStars(item["eval"]),
            isMy: isMy,
            isMyLike: isMyLike,
          });
          if (item["client"] === this.profile.id) this.isExistFeedback = true;
        }
      }

      if (this.countFeedback > 0) {
        this.evalFeedback = (eval_count / this.countFeedback).toFixed(2);
      }
      this.starsFeedback = this.getStars(this.evalFeedback);
    } catch (error) {
      console.log(error);
    }
    if (urlParams.get("is-auth")) {
      history.pushState(
        null,
        null,
        location.protocol + "//" + location.host + location.pathname
      );
    }
    this.viewProduct();
    this.getFavoriteProduct(this.product);
    this.getBasketProduct(this.product);
    this.productStatistics(this.product.id, true, false);
    if (localStorage.getItem("product_favorite")) {
      this.clickFavoriteProduct(this.product);
      localStorage.removeItem("product_favorite");
    }
    try {
      const result = await api.shop.getProductQuestions(this.product.id);
      this.countQuestions = result["data"]["results"].length;
      let count = 0;
      for (let item in result["data"]["results"]) {
        if (count !== 10) break;
        item = result["data"]["results"][item];
        count += 1;
        this.questions.push({
          text: item["text"],
          answer: item["answer_text"],
          isOpen: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
    let recommend = document.querySelector(".product-recommended");
    if (window.innerWidth >= 1200) {
      let top = 165;
      if (document.querySelector(".this-test-site")) top += 50;
      window.scrollTo({ top: top, left: 0, behavior: "smooth" });
      window.addEventListener("scroll", function () {
        let basket = document.querySelector(".product-section .product-basket");
        if (!basket) return false;
        let menu = document.querySelector(".product-section .product-tabs");
        let top = document.querySelector("footer");
        if (recommend) top = recommend;
        top = top.offsetTop - window.innerHeight / 2;
        if (window.scrollY > top) {
          menu.classList.remove("fixed");
          basket.classList.remove("fixed");
        } else if (window.scrollY > menu.offsetTop) {
          menu.classList.add("fixed");
          basket.classList.add("fixed");
        } else if (menu.classList.contains("fixed")) {
          menu.classList.remove("fixed");
          basket.classList.remove("fixed");
        }
      });
    } else {
      document.querySelector("h2.instruction").innerHTML =
        Locale.TEXTS.instructionMobile + ":";
      window.addEventListener("scroll", function () {
        let basket = document.querySelector(".product-basket");
        if (!basket) return false;
        let price = document.querySelector(".product-basket .price");
        let top = document.querySelector(".product-h3-block");
        top = top.offsetTop - window.innerHeight;
        if (window.scrollY > top) {
          price.classList.remove("fixed");
        } else if (window.scrollY > basket.offsetTop + 670) {
          price.classList.add("fixed");
        } else if (price.classList.contains("fixed")) {
          price.classList.remove("fixed");
        }
      });
    }

    let block_show = document.querySelector(
      ".product-explanation-of-the-composition"
    );
    let button_show = document.querySelector(".show-explanation");
    if (button_show) {
      button_show.onclick = function () {
        if (block_show.classList.contains("open")) {
          block_show.classList.remove("open");
          button_show.innerHTML = Locale.TEXTS.showExplanation;
        } else {
          block_show.classList.add("open");
          button_show.innerHTML = Locale.TEXTS.hideExplanation;
        }
      };
    }
    let dataItem = {
      ecommerce: {
        currencyCode: "RUB",
        detail: {
          products: [
            {
              id: String(this.product.id),
              name: String(this.product.headline_preview),
              price: this.productPrice,
            },
          ],
        },
      },
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataItem);
    window._txq.push([
      "track",
      "ViewContent",
      {
        content_ids: [this.product.id],
        category_id: this.product.categories,
        shop_id: ["65859f1e4d506e54f471fa98"],
      },
    ]);
    await api.shop.postProductCountViews(this.product.id);
  },
  methods: {
    locationHref(value) {
      let link = "/catalog/" + value;
      if (this.searchSessionHash) {
        link += "?searchSessionHash=" + this.searchSessionHash;
      }
      window.location.href = link;
    },
    openBasketProductPopup(product) {
      if (Locale.LOCALE === "en") {
        window.open(product.link);
        return false;
      }
      this.isOpenPopupBasketProduct = true;
      this.clickBasketProduct(product, 1, true, 1, null);
      let that = this;
      setTimeout(function () {
        if (that.isOpenPopupBasketProduct) {
          that.isOpenPopupBasketProduct = false;
        }
      }, 20000);
    },
    cursorGallery(event, count) {
      if (count < 2) return false;
      let element = document.querySelector(
        ".gallery-popup .swiper-slide-active .image-item"
      );
      let width = element.offsetWidth / 2;
      if (event.pageX > width) {
        if (element.classList.contains("zoom-left")) {
          element.classList.remove("zoom-left");
        }
        element.classList.add("zoom-right");
      } else {
        if (element.classList.contains("zoom-right")) {
          element.classList.remove("zoom-right");
        }
        element.classList.add("zoom-left");
      }
    },
    clickNextGallery(event) {
      let element = document.querySelector(
        ".gallery-popup .swiper-slide-active .image-item"
      );
      let width = element.offsetWidth / 2;
      const swiper = document.querySelector(".gallery-popup .swiper").swiper;
      if (event.pageX > width) swiper.slideNext();
      else swiper.slidePrev();
    },
    async viewProduct() {
      if (this.authToken) {
        try {
          let data = {
            product_id: this.product.id,
          };
          await api.shop.postShopProductUserView(data);
        } catch (error) {
          console.log(error);
        }
      } else {
        let data = localStorage.getItem("shop_viewsProducts");
        if (data) data += "," + this.product.id;
        else data = this.product.id;
        localStorage.setItem("shop_viewsProducts", data);
      }
    },
    clickOpenPopupGallery(event, index) {
      if (window.innerWidth >= 1200) {
        this.isOpenPopupGallery = true;
        this.initialSlide = index;
      } else {
        let element = document.querySelector(
          ".product-images .swiper-slide-active .image-item"
        );
        let width = element.offsetWidth / 2;
        const swiper = document.querySelector(".product-images .swiper").swiper;
        if (event.pageX > width) swiper.slideNext();
        else swiper.slidePrev();
      }
    },
    selectGiftCardType(type) {
      this.giftCardCommentSelected = null;
      this.giftCardNominalSelected = null;
      this.giftCardTypeSelected = type;
      if (type > 0) this.giftCardTypeActive = type;
    },
    async getGiftCardNominals() {
      try {
        const result = await api.shop.getProductGiftCardsNominals();
        for (let item in result["data"]["results"]) {
          item = result["data"]["results"][item];
          if (item["type"] === 6) this.virtualGiftCardNominals.push(item);
          if (item["count_available"] < 1) continue;
          if (item["type"] === 5) this.realGiftCardNominals.push(item);
        }
      } catch (error) {
        console.log(error);
      }
    },
    selectGiftCardNominal(nominal) {
      this.giftCardCommentSelected = null;
      this.giftCardNominalSelected = nominal;
      this.giftCardNominalActive = nominal.id;
    },
  },
  watch: {
    isOpenPopupBasketProduct(value) {
      this.isOpenPopup(value);
    },
    isOpenPopupDocuments(value) {
      this.isOpenPopup(value);
      if (!value) return false;
      if (window.innerWidth < 1200) {
        let height = window.innerHeight - 55;
        setTimeout(function () {
          let elements = document.querySelectorAll(".documents-popup iframe");
          for (let i = 0; i < elements.length; ++i) {
            elements[i].style.height = height + "px";
          }
        }, 100);
      }
    },
    isOpenAuth(isOpen) {
      this.isOpenPopup(isOpen);
    },
    isOpenProfileName(isOpen) {
      this.isOpenPopup(isOpen);
      if (window.innerWidth < 800) {
        setTimeout(function () {
          document.querySelector(".profile-name-popup button").innerHTML =
            "Сохранить";
        }, 100);
      }
    },
    isOpenNotOrder(isOpen) {
      this.isOpenPopup(isOpen);
    },
    isOpenExistFeedback(isOpen) {
      this.isOpenPopup(isOpen);
    },
    isOpenAddFeedback(isOpen) {
      this.isOpenPopup(isOpen);
      if (window.innerWidth < 800) {
        setTimeout(function () {
          document.querySelector(".add-feedback-popup button").innerHTML =
            "Отправить";
        }, 100);
      }
    },
    isOpenAddQuestion(isOpen) {
      this.isOpenPopup(isOpen);
      if (window.innerWidth < 800) {
        setTimeout(function () {
          document.querySelector(".add-question-popup button").innerHTML =
            "Отправить";
        }, 100);
      }
    },
    isOpenPopupGallery(isOpen) {
      this.isOpenPopup(isOpen);
    },
    openBlock() {
      let top = document.querySelector(".product-tabs");
      window.scrollTo({ top: top.offsetTop, left: 0, behavior: "smooth" });
    },
  },
};
</script>
